import React, { useContext, useEffect } from 'react';
import { UIContext } from '../../contexts/UIContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { LoginContext } from '../../contexts/LoginContext'
import { Link, useHistory } from "react-router-dom"
import Moment from "moment"
import formatValue from '../../utils/formatFieldValue.js'
import { Card, Image, Dropdown, Icon, Segment, Checkbox, Popup, Button } from 'semantic-ui-react'
import {
    Calendar as BigCalendar,
    DateLocalizer,
    momentLocalizer,
    globalizeLocalizer,
    move,
    Views,
    Navigate,
    components,
} from 'react-big-calendar'
import { mode } from 'crypto-js';





const Calendar = ({
    tableColumnGroups,
    stats,
    setSortField,
    setSortDirection,
    sortDirection,
    records,
    selected,
    handleSelection,
    model,
    view,
    relationship,
    parentModel,
    parentRecord,
    setCalendarViewFromDate,
    setCalendarViewToDate,
    setCalendarView,
    saveCalendarDateToLocalStorage,
    defaultCalendarDate,
    saveCalendarViewToLocalStorage,
    defaultCalendarView
}) => {

    const { addToEditModalQueue, refreshDataKey, refreshData, runAction, runDeletion, runBulkDeletion } = useContext(UIContext)


    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)

    let history = useHistory()

    Moment.locale("en-gb", {
        week: {
            dow:model.calendarFirstDayOfWeek || 0
        }
    })
    
    const localizer = momentLocalizer(Moment)


    return <div className='calendarHolder'>
        <Segment style={{ borderTop: `2px solid ${config.primaryColour}` }}>
            <BigCalendar
                localizer={localizer}
                defaultDate={defaultCalendarDate?new Date(defaultCalendarDate):new Date()}//will default to now if null
                showMultiDayTimes={true}
                selectable={true}
                view={view}
                popup={true}
                views={['month', 'week', 'day']}
                showAllEvents={true}
                onSelectSlot={(e)=>{
                    
                    addToEditModalQueue({
                        model,
                        parentModel,
                        parentRecord,
                        setData:{
                            [model.calendarStartField]:e.start,
                            [model.calendarEndField]:e.end
                        }
                    })
                }}
                events={records.map(r => {
                    return {
                        id: r.id,
                        record: r,
                        title: model.referToByString(r),
                        start: Moment(r[model.calendarStartField]).toDate(),
                        end: Moment(r[model.calendarEndField]).toDate(),
                        allDay: false
                    }
                })}
                //tooltipAccessor= {`title`}
                onSelectEvent={event => history.push(`/${model.name}/${event.id}`)}
                eventPropGetter={(event, start, end, isSelected)=>{
                    let colour = '#228B22'

                    //if(event.record.status == 'Provisional') colour = '#8B0000'
                    if(model.calendarColour){
                        colour = model.calendarColour(event.record)
                    }

                    return {
                        style: {
                            backgroundColor:colour
                        }
                    }
                }}
                //detect date range change
                onView={(view)=>{
                    setCalendarView(view)
                    saveCalendarViewToLocalStorage(view)
                }}
                onRangeChange={(range)=>{
                    console.log(range)
                    //if an array
                    if(Array.isArray(range)){
                        saveCalendarDateToLocalStorage(range[0])
                        setCalendarViewFromDate(Moment(range[0]))
                        setCalendarViewToDate(Moment(range[range.length-1]).endOf('day'))
                    }
                    //else object (month view)
                    else {
                        //if the range start the 1st of the month
                        let startOfMonth = Moment(range.start).date()==1?range.start:Moment(range.start).add(1, 'week').startOf('month').toString()
                        saveCalendarDateToLocalStorage(startOfMonth)

                        // saveCalendarDateToLocalStorage(range.start)
                        setCalendarViewFromDate(Moment(range.start))
                        setCalendarViewToDate(Moment(range.end))
                    }
                }}
                //dayLayoutAlgorithm='no-overlap'
                //selectable
                //popup={true}
                // [{
                //     id: 23,
                //     title: 'Go to the gym',
                //     start: new Date(2020, 10, 14, 18, 30, 0),
                //     end: new Date(2020, 10, 14, 20, 0, 0),
                // }]}
                // startAccessor="start"
                // endAccessor="end"
                // allDayAccessor="allDay"

                // style={{ height: 800 }}
            />
        </Segment>
    </div>
}

export default Calendar